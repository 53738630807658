import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Check, ArrowRight } from "react-feather"
import slugify from "slugify"
import { useCookies } from "react-cookie"
import GoogleMap from "../components/GoogleMap"
import HelmetHead from "../components/HelmetHead"
import TimeSchedule from "../svgs/icons/duotone-icons/Code/Time-schedule.svg"
import Euro from "../svgs/icons/duotone-icons/Shopping/Euro.svg"
import ChartLine from "../svgs/icons/duotone-icons/Shopping/chart-line-1.svg"

import ClipboardList from "../svgs/icons/duotone-icons/Communication/Clipboard-list.svg"
import Group from "../svgs/icons/duotone-icons/Communication/Group.svg"
import ClipboardCheck from "../svgs/icons/duotone-icons/Communication/Clipboard-check.svg"

import Curve1 from "../svgs/shapes/curves/curve-1.svg"
import AngleRight from "../svgs/shapes/angles/angle-end.svg"

import LeadGeneratorLoadable from "../components/generator/LeadGeneratorLoadable"
const CTA2 = Loadable(() => import("../components/CTA2"))
const CTA = Loadable(() => import("../components/CTA"))
const Newsletter = Loadable(() => import("../components/Newsletter"))
import BackgroundSection from "../components/BackgroundSection"
import smoothScroll from "../components/smoothScroll"
import LayoutContext from "../components/LayoutContext"
import Loadable from "@loadable/component"

export const query = graphql`
  query CityQuery($id: Int!) {
    strapiCity(strapi_id: { eq: $id }) {
      id
      title
      slug
      coordinates {
        latitude
        longitude
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        keywords
      }
      header_background
      sections {
        enabled
        heading
        text {
          data {
            text
          }
        }
        id
      }
      city
      stats {
        id
        name
        number
        unit
      }
      strapi_id
    }
    allStrapiCity(limit: 40) {
      edges {
        node {
          id
          city
          slug
        }
      }
    }
  }
`

const City = ({ data }) => {
  const city = data.strapiCity
  const cities = data.allStrapiCity.edges

  // define if lead-generator is used in page!
  const fixedPathname = `/de/${city.slug}/`

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        color: city.header_background ? city.header_background : "dark",
        className: "city-header",
      },
    }))
  }, [])

  const [cookies] = useCookies()
  const mzCookies = cookies.maklerzentrale || {}

  return (
    <>
      <HelmetHead
        title={city.seo.metaTitle}
        description={city.seo.metaDescription}
        keywords={city.seo.keywords}
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${fixedPathname}`,
          },
        ]}
      />
      <BackgroundSection
        gatsbyImageData={city.image.localFile.childImageSharp.gatsbyImageData}
        className="bg-cover"
      >
        <div className="container">
          <div
            className="row justify-content-center align-items-end pb-14"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1
                className="display-2 fw-bold text-white text-shadow-custom"
                dangerouslySetInnerHTML={{
                  __html: city.title,
                }}
              />

              {/*<p className="lead text-white mb-0 fw-bold text-shadow-custom">*/}
              {/*  Der passende Makler zu Ihrer Immobilie ist schnell gefunden!*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
          <Curve1 />
        </div>
      </div>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-9">
              <div
                className="card card-bleed shadow-light-lg mx-md-auto mt-n12 mt-md-n14"
                style={{ minHeight: "495px", maxWidth: "767px" }}
              >
                <div className="scroll-beacon" id="makler-finden" />
                <LeadGeneratorLoadable
                  leadGenerator="makler"
                  setPath={fixedPathname}
                  urlInitParams={`?city=${city.city}&locationStepVisited=true`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center mb-9">
              <h2 className="fw-bold">
                Gute Gründe für einen
                <br />
                <span className="text-primary">
                  Immobilienmakler in {city.city}
                </span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#4ac25a" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Schneller Verkauf Ihrer Immobilie
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <TimeSchedule />
                    </div>
                  </div>
                  <p className="text-muted">
                    Ein Immobilienmakler bewahrt Sie vor zeitintensiven und
                    aufwändigen Recherchearbeiten und
                    Besichtigungs-Organisationen. Zudem sorgt er für eine
                    gewinnbringende und besonders zeiteffiziente
                    Vermarktungs-Strategie.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#153f91" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Bestmöglicher Verkaufspreis
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <Euro />
                    </div>
                  </div>
                  <p className="text-muted">
                    Verkaufen will gelernt sein. Ein Makler verfügt über die
                    spezifischen Kommunikationsfähigkeiten, die dafür nötig
                    sind. Er handelt stets zu 100% in ihrem Interesse, da
                    lediglich bei erfolgreicher Vermittlung eine Provision vom
                    Verkaufspreis anfällt.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#ff0000" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">Sichere Werteinschätzung</h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <ChartLine />
                    </div>
                  </div>
                  <p className="text-muted">
                    Mit der professionellen Immobilienbewertung durch einen
                    Makler, steht und fällt der marktgerechte Verkaufserlös
                    Ihrer Immobilie. Überlassen Sie hier nichts dem Zufall und
                    wenden Sie sich hier an einen Experten.
                  </p>
                  <br className="d-none d-lg-inline d-xl-none" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#ffb900" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">Rechtliche Sicherheit</h4>
                    </div>
                    <div
                      className="icon text-primary ms-5 fw-bolder"
                      style={{ fontSize: "48px", lineHeight: "48px" }}
                    >
                      {/*<Paragraph />*/}§
                    </div>
                  </div>
                  <p className="text-muted">
                    Fehlerhafte Absprachen oder Vertragsschlüsse werden Ihnen in
                    aller Regel langfristige und unter Umständen auch teure
                    Probleme bereiten. Ein Immobilienmakler weiß es, Miet- oder
                    Kaufverträge zu erstellen und wiegt sie stets auf der
                    sicheren Seite.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTA2>
        <blockquote className="blockquote">
          <p className="h2 text-white">Wir sind da wenn Sie uns brauchen.</p>
        </blockquote>
      </CTA2>

      <section className="py-8 py-md-11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">
                Empfehlung für Immobilienmakler in {city.city}
              </h2>
              <h3 className="text-muted mb-7 mb-md-9">
                in 3 einfachen Schritten
              </h3>
            </div>
          </div>
          <div className="row g-0 mb-7 mb-md-9">
            <div className="col-12 col-md-4 text-center">
              <div className="row mb-5">
                <div className="col" />
                <div className="col-auto">
                  <div
                    className="icon text-primary mb-3"
                    data-sal="slide-down"
                    data-sal-delay="200"
                    data-sal-duration="700"
                  >
                    <ClipboardList />
                  </div>
                </div>
                <div className="col">
                  <hr className="d-none d-md-block" />
                </div>
              </div>
              <h3 className="fw-bold">
                1. Daten zu
                <br />
                Ihrer Immobilie
              </h3>
              <p className="text-muted mb-6 mb-md-0">
                Unser intuitives Formular fragt in wenigen Schritten die
                wichtigsten Daten zu Ihrer Immobilie ab.
              </p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="row mb-5">
                <div className="col">
                  <hr className="d-none d-md-block" />
                </div>
                <div className="col-auto">
                  <div
                    className="icon text-primary mb-3"
                    data-sal="slide-down"
                    data-sal-delay="500"
                    data-sal-duration="700"
                  >
                    <Group />
                  </div>
                </div>
                <div className="col">
                  <hr className="d-none d-md-block" />
                </div>
              </div>
              <h3 className="fw-bold">
                2. Passende Makler-
                <br />
                empfehlung erhalten
              </h3>
              <p className="text-muted mb-6 mb-md-0">
                Basierend auf den Daten senden wir Ihnen die Top 3 Makler für
                Ihr Objekt per E-Mail zu.
              </p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="row mb-5">
                <div className="col">
                  <hr className="d-none d-md-block" />
                </div>
                <div className="col-auto">
                  <div
                    className="icon text-primary mb-3"
                    data-sal="slide-down"
                    data-sal-delay="800"
                    data-sal-duration="700"
                  >
                    <ClipboardCheck />
                  </div>
                </div>
                <div className="col" />
              </div>
              <h3 className="fw-bold">
                3. Makler beauftragen
                <br />& erfolgreich verkaufen
              </h3>
              <p className="text-muted mb-0">
                Wählen Sie aus unserer Empfehlung bequem den am besten zu Ihnen
                passenden Makler.
              </p>
            </div>
          </div>

          <CTA className="mb-n14 pt-6 pt-md-8" />
        </div>
      </section>

      <section className="pt-14 pb-8 pb-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-5 mb-7 mb-md-9">
              <span className="badge rounded-pill bg-success-soft mb-3">
                <span className="h6 text-uppercase">Auswahlkriterien</span>
              </span>

              <h2 className="fw-bold">
                So entstehen unsere
                <br />
                <span className="text-success">Maklerempfehlungen</span>.
              </h2>

              <p className="fs-lg text-muted">
                Leider existieren am Markt immer wieder auch unseriöse und wenig
                erfahrene Immobilien­makler, die es zu meiden gilt. Um Sie davor
                zu schützen, gehen wir bei unseren Makler­empfehlungen mit
                größter Sorgfalt vor und legen vor allem auf die folgenden
                Auswahlkriterien gesteigerten Wert:
              </p>

              <a
                href="#makler-finden"
                className="btn btn-primary-desat-soft"
                onClick={smoothScroll}
              >
                Richtigen Makler finden
              </a>
            </div>

            <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
              <div className="card card-border border-success shadow-lg mb-5">
                <div className="card-body">
                  <div className="list-group list-group-flush text-secondary">
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Regionale Aktivität und Erfolge
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Positive Kundenrezensionen
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Internetauftritt und Visitenkarte
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">Sichtung der Exposés</p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Gute Erreichbarkeit & Professionelle Kommunikation
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">Vertrauenswürdigkeit</p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Referenzen und Qualifikationen
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                    <div className="list-group-item py-2 d-flex align-items-center">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Passgenauigkeit zu Ihrer Immobilie
                        </p>
                      </div>
                      <div className="badge badge-rounded-circle bg-success-soft ms-4">
                        <Check size={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-8 pt-md-0 border-bottom bg-light">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              {mzCookies.thirdParty ? (
                <>
                  <div className="ratio ratio-16x9 d-md-none">
                    <GoogleMap
                      center={[
                        (city.coordinates && city.coordinates.latitude) ||
                          51.165691,
                        (city.coordinates && city.coordinates.longitude) ||
                          10.451526,
                      ]}
                      zoom={city.coordinates ? 13 : 5.5}
                      style={{ zIndex: 1 }}
                    />
                  </div>

                  <div className="position-relative h-100 vw-50 float-end d-none d-md-block">
                    <GoogleMap
                      center={[
                        (city.coordinates && city.coordinates.latitude) ||
                          51.165691,
                        (city.coordinates && city.coordinates.longitude) ||
                          10.451526,
                      ]}
                      zoom={city.coordinates ? 13 : 5.5}
                      className="h-100 w-100"
                      style={{ zIndex: 1 }}
                    />

                    <div
                      className="shape shape-end shape-fluid-y svg-shim text-light"
                      style={{ zIndex: 2 }}
                    >
                      <AngleRight />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-12 col-md-6 py-8 py-md-11 py-lg-12">
              <h2>
                Immobilienmarkt in {city.city}:<br />
                Alles was Sie wissen müssen
              </h2>
              <p className="fs-lg text-secondary mb-6">
                {city.sections.map(
                  (section, i) =>
                    section.enabled && (
                      <React.Fragment key={i}>
                        <a
                          href={`#${slugify(section.heading, {
                            lower: true,
                          })}`}
                          onClick={smoothScroll}
                        >
                          <ArrowRight size={15} /> {section.heading}
                        </a>
                        <br />
                      </React.Fragment>
                    )
                )}
              </p>

              <div className="d-flex">
                {city.stats.map((stat, i) => (
                  <React.Fragment key={i}>
                    <div
                      className={`${
                        i === 0
                          ? "pe-5 pe-md-3 pe-lg-5"
                          : city.stats.length === i + 1
                          ? "ps-5 ps-md-3 ps-lg-5"
                          : "px-5 px-md-3 px-lg-5"
                      }`}
                    >
                      <h3 className="text-success mb-0">
                        {Number(stat.number).toFixed(
                          parseInt(stat.number).toString().length <= 2 ? 2 : 0
                        )}{" "}
                        {stat.unit}
                      </h3>
                      <p className="text-secondary mb-0">{stat.name}</p>
                    </div>
                    {city.stats.length === i + 1 ? null : (
                      <div className="border-start border-gray-300" />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 border-bottom bg-gray-200">
        <div className="container">
          {city.sections.map(
            (section, i) =>
              section.enabled && (
                <div className="py-3 py-md-4" key={i}>
                  <div
                    className="scroll-beacon"
                    id={slugify(section.heading, { lower: true })}
                  />
                  <h3>{section.heading}</h3>

                  <p className="text-muted mb-6">{section.text.data.text}</p>
                </div>
              )
          )}
          <div className="row justify-content-center">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <a
                href="#makler-finden"
                className="btn btn-block btn-primary d-flex align-items-center lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 border-top border-bottom bg-light">
        <div className="container">
          <h3>Weitere Städte</h3>
          <div className="row">
            {cities.map(({ node: otherCity }, i) =>
              otherCity.city !== city.city ? (
                <div className="col-4 col-sm-3 col-md-2" key={i}>
                  <Link to={`/de/${otherCity.slug}/`}>{otherCity.city}</Link>
                </div>
              ) : null
            )}
          </div>
        </div>
      </section>

      <section className="pt-8">
        <div className="container">
          <div className="pb-6 pb-md-8 border-bottom border-gray-300">
            <Newsletter />
          </div>
        </div>
      </section>
    </>
  )
}

export default City
